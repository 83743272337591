import { BookColor } from '@remento/types/book';

import { ColorsTokens } from '@/styles/theme/colorTokens';

export function getBookTextColor(bookColor: BookColor | null) {
  const theme = getBookColorTheme(bookColor);

  if (theme === 'light') {
    return ColorsTokens['on-surface-primary'];
  }

  return ColorsTokens['on-primary'];
}

// light theme is default
export function getBookColorTheme(bookColor: BookColor | null): 'light' | 'dark' {
  if (bookColor == null || [BookColor.LIGHT_BLUE, BookColor.BLUSH, BookColor.NATURAL].includes(bookColor)) {
    return 'light';
  }

  return 'dark';
}
